<template>
  <div class="h-screen flex flex-col">
    <div class="flex flex-row flex-grow xl:h-full relative">
      <!-- Sidebar -->
      <sidebar />
      <div class="w-full flex flex-col flex-nowrap">
        <!-- Navbar -->
        <navbar />

        <div class="flex-1 overflow-hidden pt-20 ml-0 mr-0">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./layouts/navbar/index";
import sidebar from "./layouts/sidebar";

export default {
  components: {
    navbar,
    sidebar
  }
};
</script>
